import {
  CLEAR_ADJUSTMENT_EDITS,
  CLEAR_PENDING_LOCK_DESK_MODIFICATIONS,
  REMOVE_REQUEST_BY_ID,
  SET_ADJUSTMENTS_TOTALS,
  SET_COUNTS_BY_TYPE,
  SET_EDITED_NEXT_PRICE_ADJUSTMENTS,
  SET_EDITED_NEXT_RATE_ADJUSTMENTS,
  SET_INITIAL_TO_EXCEPTION_ADJUSTMENT_IDS,
  SET_INVESTOR_LOAN_NUMBER,
  SET_IS_EDITING_BUY_SIDE,
  SET_IS_EDITING_SELL_SIDE,
  SET_IS_REQUEST_DETAILS_LOADING,
  SET_LOCK_REQUESTS,
  SET_PENDING_LOCK_DESK_MODIFICATIONS,
  SET_REQUEST_DETAILS,
  SET_REQUEST_LOADING_STATE,
  SET_SELL_SIDE_BEST_EXECUTION_PRICE,
  SET_SHOW_VALIDATION,
  SET_TEMP_NEXT_PRICE_ADJUSTMENTS,
  SET_TEMP_NEXT_RATE_ADJUSTMENTS,
  TEMP_NEXT_ADJUSTMENTS_ADD,
  TEMP_NEXT_ADJUSTMENTS_DELETE,
  TEMP_NEXT_ADJUSTMENTS_MODIFY,
} from './mutationTypes.js';
import lodash from 'lodash';
import orderBy from 'lodash/orderBy';

import { onErrorHandler } from '@shared/utils/errorHandlers.js';

import {
  ADJUSTMENT_ACTIONS,
  ADJUSTMENT_KIND,
  ADJUSTMENT_TYPE,
  LOS_WRITEBACK_QUEUE_ITEM_STATUS,
  PERuleCategories,
  PRICING_ROUND,
  RULE_TARGET,
} from '@shared/constants';

import { isUserException } from '@shared/api/errors.js';
import api from '@shared/services/api.js';
import clone from 'lodash/cloneDeep';
import Vue from 'vue';
import {
  filterAdjustmentsBySide,
  filterPriceAdjustments,
  filterRateAdjustments,
  filterWorkflowAdjustments,
  getWorkflowExtensionAdjustment,
} from '@pe/services/adjustments.js';
import { strToPrecision } from '@shared/utils/converters.js';
import { getLoanAmount } from '@pe/functions/helpers';

const mappedLockRateActions = {
  'Initial Lock': 'initial-locks',
  'Lock Extension': 'lock-extensions',
  Relock: 'relocks',
  'Float Down': 'float-downs',
  Reprice: 'reprices',
  'Price Exception': 'price-exceptions',
};

function approveOrDenyLockRequest(payload) {
  return api.post('/pe/api/lock-requests/reviewal/pending/', payload, {
    raiseWarnings: true,
  });
}

function crossflowLockRequest(payload) {
  return api.post('/pe/api/crossflow-requests/reviewal/', payload, {
    raiseWarnings: true,
  });
}

export const LOCK_DESK_MODIFICATIONS_BUY_SIDE = 'buySide';
export const LOCK_DESK_MODIFICATIONS_SELL_SIDE = 'sellSide';
export const LOCK_DESK_MODIFICATIONS_CLEAR_ALL = '_all_';

export const LOCK_REVIEWAL_ACTION = {
  PENDING: 'pending',
  APPROVE: 'approve',
  DENY: 'deny',
};

export const initialState = {
  lockRequests: [],
  countsByType: {},
  requestDetails: {},
  isRequestDetailsLoading: false,
  showValidation: false,
  pendingLockDeskModifications: {
    [LOCK_DESK_MODIFICATIONS_BUY_SIDE]: {},
    [LOCK_DESK_MODIFICATIONS_SELL_SIDE]: {},
  },
  isEditingBuySide: false,
  isEditingSellSide: false,
  sellSideBestExecutionPrice: null,
  // Store our edited copies of price and rate adjustments for a request.
  // This copy will determine what is sent back to the reviewal endpoint.
  editedNextPriceAdjustments: [],
  editedNextRateAdjustments: [],
  // Also, store more intermediate edits to allow a "soft" reset.
  tempNextPriceAdjustments: [],
  tempNextRateAdjustments: [],
  nextAdjustmentId: -1,
  adjustmentsTotals: {},

  initialToExceptionLockAdjustmentIds: {},
};

const getters = {
  // backend already checks for internal parent or internal children existing.
  // check children locks on frontend as extra sanity check that it got loaded.
  isCrossWorkflow: (_state, getters) =>
    getters.workflowDetails?.isCrossWorkflow &&
    getters.workflowDetails?.childLockRequests?.length > 0,
  lockRequests: state => state.lockRequests,
  countsByType: state => state.countsByType,
  requestDetails: state => state.requestDetails,
  isRequestDetailsLoading: state => state.isRequestDetailsLoading,
  brokerCompPlan: state => state.requestDetails?.loan?.comp,
  loanAmount: state => state.requestDetails?.loan?.loanAmount,
  totalLoanAmount: state => {
    const originalPriceRequest =
      state.requestDetails?.workflow?.originalRequest;
    if (originalPriceRequest) {
      const product = originalPriceRequest.results.find(
        r => r.id === state.requestDetails?.workflow?.pe3ProductId,
      );
      return getLoanAmount(state.requestDetails?.loan, product);
    }
  },
  workflowDetails: state => state.requestDetails?.workflow,
  sellSideBestExecutionPrice: state => state.sellSideBestExecutionPrice,
  requestedById: state => state.requestDetails.workflow.requestedById,
  lockDeskParameters: (state, getters) => {
    const currentLockDeskParameters =
      state.requestDetails?.workflow?.formatted_info?.lockDeskParameters || [];

    if (getters.isRequestPending) {
      // For pending requests grab the previously submitted values so that they can
      // see what values are currently in the LOS for the fields.
      const previousLockDeskParameters =
        state.requestDetails?.workflow?.previous?.formatted_info
          ?.lockDeskParameters;
      if (!previousLockDeskParameters) {
        return currentLockDeskParameters;
      }
      // Be sure we aren't update values we are storing in state
      const currentLockDeskParametersWithPreviousValues = [
        ...currentLockDeskParameters,
      ];
      // Loop over the parameters defined for the current lock and pull over any values that may
      // have been submitted for the same field on the previous lock request
      for (const lockDeskParameter of currentLockDeskParametersWithPreviousValues) {
        const previousMatchingParameter = previousLockDeskParameters.find(
          param => param.id === lockDeskParameter.id,
        );
        if (previousMatchingParameter) {
          lockDeskParameter.value = previousMatchingParameter.value;
        }
      }
      return currentLockDeskParametersWithPreviousValues;
    }
    // Completed requests should always show the values that were submitted for this lock
    return currentLockDeskParameters;
  },
  lockDeskParametersCWF: (state, getters) => {
    const currentLockDeskParameters =
      state.requestDetails?.workflow?.childLockRequests?.[0]?.formatted_info
        ?.lockDeskParameters;
    if (getters.isRequestPendingCWF) {
      // For pending requests grab the previously submitted values so that they can
      // see what values are currently in the LOS for the fields.
      const previousLockDeskParameters =
        state.requestDetails?.workflow?.previous?.childLockRequests?.[0]
          ?.formatted_info?.lockDeskParameters;
      if (!previousLockDeskParameters) {
        return currentLockDeskParameters;
      }
      // Be sure we aren't update values we are storing in state
      const currentLockDeskParametersWithPreviousValues = [
        ...currentLockDeskParameters,
      ];
      // Loop over the parameters defined for the current lock and pull over any values that may
      // have been submitted for the same field on the previous lock request
      for (const lockDeskParameter of currentLockDeskParametersWithPreviousValues) {
        const previousMatchingParameter = previousLockDeskParameters.find(
          param => param.id === lockDeskParameter.id,
        );
        if (previousMatchingParameter) {
          lockDeskParameter.value = previousMatchingParameter.value;
        }
      }
      return currentLockDeskParametersWithPreviousValues;
    }
    // Completed requests should always show the values that were submitted for this lock
    return currentLockDeskParameters;
  },
  isRequestPending: state => {
    return (
      state.requestDetails?.workflow?.writebackStatus ===
        LOS_WRITEBACK_QUEUE_ITEM_STATUS.AWAITING_APPROVAL ||
      state.requestDetails?.workflow?.approvalWritebackStatus ===
        LOS_WRITEBACK_QUEUE_ITEM_STATUS.AWAITING_APPROVAL
    );
  },
  isRequestPendingCWF: state => {
    return (
      state.requestDetails?.workflow?.childLockRequests?.[0]
        ?.writebackStatus ===
        LOS_WRITEBACK_QUEUE_ITEM_STATUS.AWAITING_APPROVAL ||
      state.requestDetails?.workflow?.childLockRequests?.[0]
        ?.approvalWritebackStatus ===
        LOS_WRITEBACK_QUEUE_ITEM_STATUS.AWAITING_APPROVAL
    );
  },
  previousAdjustments: state => {
    const { previous } = state.requestDetails?.workflow || {};
    const previousAdjustments = [...(previous?.adjustments || [])] || [];
    const action = previous?.action;
    const extensionAdjustment = getWorkflowExtensionAdjustment(previous);
    if (extensionAdjustment) {
      previousAdjustments.push(extensionAdjustment);
    }

    return filterWorkflowAdjustments(
      previousAdjustments,
      action,
      previous?.exceptions ?? [],
    );
  },
  previousPriceAdjustments: (_, getters, rootState, rootGetter) => {
    return filterPriceAdjustments(
      getters.previousAdjustments,
      rootGetter['core/canViewHiddenAdjustments'],
    );
  },
  previousRateAdjustments: (_, getters) => {
    return filterRateAdjustments(getters.previousAdjustments);
  },
  nextPriceAdjustmentExceptions(state, getters) {
    if (getters.workflowDetails?.proposedException) {
      return lodash.slice(
        orderBy(getters.workflowDetails.exceptions, ['id'], ['desc']),
        1,
      );
    }

    return getters.workflowDetails?.exceptions || [];
  },
  nextAdjustments: (state, getters) => {
    const { workflow } = state.requestDetails || {};
    const adjustments = workflow == null ? [] : [...workflow.adjustments];

    const action = workflow?.action;
    const extensionAdjustment = getWorkflowExtensionAdjustment(workflow);
    if (extensionAdjustment) {
      adjustments.push(extensionAdjustment);
    }

    return filterWorkflowAdjustments(
      adjustments,
      action,
      getters.nextPriceAdjustmentExceptions,
    );
  },
  nextPriceAdjustments: (_, getters, rootState, rootGetter) => {
    return filterPriceAdjustments(
      getters.nextAdjustments,
      rootGetter['core/canViewHiddenAdjustments'],
    );
  },
  nextRateAdjustments: (_, getters) => {
    return filterRateAdjustments(getters.nextAdjustments);
  },
  showValidation: state => state.showValidation,
  isEditingBuySide: state => state.isEditingBuySide,
  isEditingSellSide: state => state.isEditingSellSide,
  isEditingAnySide: (_, getters) =>
    getters.isEditingBuySide || getters.isEditingSellSide,
  isSubmittedToLockDesk: (_, getters) =>
    getters.workflowDetails?.formatted_info?.priceException
      ?.submittedToLockDesk,
  isSubmittedToLockDeskCWF: (_, getters) =>
    getters.workflowDetails?.childLockRequests?.[0]?.formatted_info
      ?.priceException?.submittedToLockDesk,
  pendingLockDeskModifications: state => state.pendingLockDeskModifications,
  pendingBuySideLockDeskModifications: (_, getters) =>
    getters.pendingLockDeskModifications.buySide,
  pendingSellSideLockDeskModifications: (_, getters) =>
    getters.pendingLockDeskModifications.sellSide,
  hasPendingLockDeskModifications: (_, getters) =>
    !lodash.isEmpty(getters.pendingBuySideLockDeskModifications ?? {}) ||
    !lodash.isEmpty(getters.pendingSellSideLockDeskModifications ?? {}) ||
    !lodash.isEmpty(getters.getPendingAdjustments ?? {}),
  hasPendingSellSideLockDeskModifications: (_, getters) => {
    const pendingSellSideAdj = getters.getPendingAdjustments.filter(
      adj => adj.is_sell_side,
    );

    return (
      !lodash.isEmpty(getters.pendingSellSideLockDeskModifications ?? {}) ||
      !lodash.isEmpty(pendingSellSideAdj ?? {})
    );
  },

  investorLoanNumber: state =>
    state.requestDetails.workflow.formatted_info.investorLoanNumber,
  getNextAdjustmentsBySide: (_, getters) => (adjType, side) => {
    const adjustments =
      adjType === ADJUSTMENT_TYPE.RATE
        ? getters.nextRateAdjustments
        : getters.nextPriceAdjustments;
    return filterAdjustmentsBySide(adjustments, side);
  },
  getAllEditedAdjustments: state => {
    return [
      ...state.editedNextPriceAdjustments,
      ...state.editedNextRateAdjustments,
    ];
  },
  getTempAdjustmentsBySide: state => (adjType, side) => {
    const adjustments =
      adjType === ADJUSTMENT_TYPE.RATE
        ? state.tempNextRateAdjustments
        : state.tempNextPriceAdjustments;
    return filterAdjustmentsBySide(adjustments, side);
  },
  getAdjustmentsTotal: state => id => {
    return state.adjustmentsTotals[id] || 0;
  },
  getPendingAdjustments: (_, getters) => {
    const pendingAdj = [];

    for (const editedAdj of getters.getAllEditedAdjustments) {
      if (editedAdj.id < 0) {
        editedAdj.action = ADJUSTMENT_ACTIONS.CREATE;
        pendingAdj.push(editedAdj);
        continue;
      }
      const nextAdj = getters.nextAdjustments.find(adj => {
        return (
          adj.id === editedAdj.id &&
          (Number(adj.value) !== Number(editedAdj.value) ||
            adj.description !== editedAdj.description ||
            adj.is_buy_side !== editedAdj.is_buy_side ||
            adj.is_sell_side !== editedAdj.is_sell_side)
        );
      });
      if (nextAdj) {
        const noSideAdj = !editedAdj.is_buy_side && !editedAdj.is_sell_side;
        editedAdj.action = noSideAdj
          ? ADJUSTMENT_ACTIONS.DELETE
          : ADJUSTMENT_ACTIONS.MODIFY;
        pendingAdj.push(editedAdj);
      }
    }
    for (const adj of getters.nextAdjustments) {
      if (
        !getters.getAllEditedAdjustments.find(
          editedAdj => editedAdj.id === adj.id,
        )
      ) {
        adj.action = ADJUSTMENT_ACTIONS.DELETE;
        pendingAdj.push(adj);
      }
    }
    return pendingAdj;
  },
  getExceptionPendingAdjustments(state, getters) {
    // gets exception adjustments ids otherwise use current (usually -1 if there is no match)
    return getters.getPendingAdjustments?.map(adj => ({
      ...adj,
      id: state.initialToExceptionLockAdjustmentIds?.[adj.id] || adj.id,
    }));
  },
  getNextAdjustmentsWithBestEx(state, getters, rootState, rootGetter) {
    if (state.sellSideBestExecutionPrice) {
      let nextAdjustments = clone(getters.nextAdjustments);
      const bestExAdjustments =
        state.sellSideBestExecutionPrice?.adjustments || [];

      // Split the shared adjustments
      for (const adj of nextAdjustments) {
        if (adj.is_sell_side && adj.is_buy_side) {
          adj.is_sell_side = false;
        }
      }

      // Clear existing sell side adjustments
      nextAdjustments = nextAdjustments.filter(
        adj => adj.is_sell_side === false,
      );

      for (const adj of bestExAdjustments) {
        const type =
          adj.target === RULE_TARGET.PRICE
            ? ADJUSTMENT_TYPE.PRICE
            : ADJUSTMENT_TYPE.RATE;
        adj.id = state.nextAdjustmentId;
        state.nextAdjustmentId--;
        adj.type = type;
        adj.description = adj.ruleName;
        adj.kind = ADJUSTMENT_KIND.ADJUSTMENT;
        adj.rule_category =
          adj.description === PRICING_ROUND
            ? PERuleCategories.Margin
            : adj.category;
        const valueToUse =
          adj.resultEquationValue !== undefined
            ? adj.resultEquationValue
            : adj.adjustment;
        adj.value = strToPrecision(Number(valueToUse), 3);
        adj.is_buy_side = false;
        adj.is_sell_side = true;
        adj.is_manual = true;
        adj.rule_id = adj.ruleId;
      }

      const allAdjustments = [...nextAdjustments, ...bestExAdjustments];
      return {
        priceAdjustments: filterPriceAdjustments(
          allAdjustments,
          rootGetter['core/canViewHiddenAdjustments'],
        ),
        rateAdjustments: filterRateAdjustments(allAdjustments),
      };
    } else {
      return {
        priceAdjustments: getters.nextPriceAdjustments,
        rateAdjustments: getters.nextRateAdjustments,
      };
    }
  },
  shouldClearSellSide(state, getters) {
    return !!getters.pendingSellSideLockDeskModifications?.clearSellSide;
  },
};

const mutations = {
  [SET_LOCK_REQUESTS](state, lockRequests) {
    state.lockRequests = lockRequests;
  },
  [SET_SELL_SIDE_BEST_EXECUTION_PRICE](state, selectedPrice) {
    state.sellSideBestExecutionPrice = selectedPrice;
  },
  [REMOVE_REQUEST_BY_ID](state, requestId) {
    const removedRequest = state.lockRequests.find(
      request => request.requestId === requestId,
    );
    state.lockRequests = state.lockRequests.filter(
      request => request.requestId !== requestId,
    );
    const tab = mappedLockRateActions[removedRequest?.action];
    if (tab) {
      const updatedTabs = { ...state.countsByType };
      updatedTabs[tab] = state.countsByType[tab] - 1;
      state.countsByType = updatedTabs;
    }
  },
  [SET_REQUEST_LOADING_STATE](state, { requestId, isLoading }) {
    state.lockRequests = state.lockRequests.map(request => {
      if (request.requestId === requestId) {
        return { ...request, isLoading };
      }
      return request;
    });
  },
  [SET_COUNTS_BY_TYPE](state, countsByType) {
    state.countsByType = countsByType;
  },
  [SET_INITIAL_TO_EXCEPTION_ADJUSTMENT_IDS](state) {
    state.initialToExceptionLockAdjustmentIds = {};

    // order ids least to greatest
    const initialAdjustments =
      state.requestDetails.workflow?.adjustments?.sort((l, r) => {
        return l['id'] - r['id'];
      }) ?? [];
    const peAdjustments =
      state.requestDetails.workflow?.childLockRequests?.[0]?.adjustments?.sort(
        (l, r) => {
          return l['id'] - r['id'];
        },
      ) ?? [];

    // map ids between initial and pe adjustments
    for (let i = 0; i < initialAdjustments.length; i++) {
      if (peAdjustments[i]?.id) {
        state.initialToExceptionLockAdjustmentIds[initialAdjustments[i].id] =
          peAdjustments[i].id;
      }
    }
  },
  [SET_REQUEST_DETAILS](state, requestDetails) {
    const adjustmentsEdited = {
      ...(requestDetails.workflow?.adjustmentsEdited || {}),
    };

    state.requestDetails = {
      ...requestDetails,
      workflow: {
        ...requestDetails.workflow,
        sellSide: {
          ...(requestDetails.workflow?.sellSide || {}),
          adjustmentsEdited,
        },
      },
    };
  },
  [SET_IS_REQUEST_DETAILS_LOADING](state, isLoading) {
    state.isRequestDetailsLoading = isLoading;
  },
  [SET_INVESTOR_LOAN_NUMBER](state, investorLoanNumber) {
    if (state.requestDetails?.workflow?.formatted_info) {
      state.requestDetails.workflow.formatted_info.investorLoanNumber =
        investorLoanNumber;
    }
  },
  [SET_SHOW_VALIDATION](state, showValidation) {
    state.showValidation = showValidation;
  },
  [SET_PENDING_LOCK_DESK_MODIFICATIONS](state, { lockSide, payload }) {
    const currentLockSideModifications =
      state.pendingLockDeskModifications[lockSide];
    for (const [key, value] of Object.entries(payload)) {
      currentLockSideModifications[key] = value;
    }
    // Replace the object to trigger vue reactivity
    state.pendingLockDeskModifications[lockSide] = {
      ...currentLockSideModifications,
    };
  },
  [CLEAR_PENDING_LOCK_DESK_MODIFICATIONS](
    state,
    { lockSide, field = LOCK_DESK_MODIFICATIONS_CLEAR_ALL },
  ) {
    if (field === LOCK_DESK_MODIFICATIONS_CLEAR_ALL) {
      state.pendingLockDeskModifications[lockSide] = {};
    } else {
      const allPendingModifications =
        state.pendingLockDeskModifications[lockSide];
      delete allPendingModifications[field];
      state.pendingLockDeskModifications[lockSide] = {
        ...allPendingModifications,
      };
    }
  },
  [SET_IS_EDITING_BUY_SIDE](state, isEditing) {
    state.isEditingBuySide = isEditing;
  },
  [SET_IS_EDITING_SELL_SIDE](state, isEditing) {
    state.isEditingSellSide = isEditing;
  },
  [SET_EDITED_NEXT_PRICE_ADJUSTMENTS](state, adjustments) {
    state.editedNextPriceAdjustments = Object.freeze(clone(adjustments));
  },
  [SET_EDITED_NEXT_RATE_ADJUSTMENTS](state, adjustments) {
    state.editedNextRateAdjustments = Object.freeze(clone(adjustments));
  },
  [SET_TEMP_NEXT_PRICE_ADJUSTMENTS](state, adjustments) {
    state.tempNextPriceAdjustments = clone(adjustments);
  },
  [SET_TEMP_NEXT_RATE_ADJUSTMENTS](state, adjustments) {
    state.tempNextRateAdjustments = clone(adjustments);
  },
  [TEMP_NEXT_ADJUSTMENTS_ADD](state, { adjustmentType, adjustment }) {
    adjustment.id = state.nextAdjustmentId;
    state.nextAdjustmentId--;
    if (adjustmentType === ADJUSTMENT_TYPE.RATE) {
      state.tempNextRateAdjustments.push(adjustment);
    } else {
      state.tempNextPriceAdjustments.push(adjustment);
    }
  },
  [TEMP_NEXT_ADJUSTMENTS_MODIFY](state, { adjustmentType, adjustment }) {
    const adjustments =
      adjustmentType === ADJUSTMENT_TYPE.RATE
        ? state.tempNextRateAdjustments
        : state.tempNextPriceAdjustments;
    const adjustmentIndex = adjustments.findIndex(
      adj => adj.id === adjustment.id,
    );
    adjustments.splice(adjustmentIndex, 1, adjustment);
  },
  [TEMP_NEXT_ADJUSTMENTS_DELETE](state, { adjustmentType, adjustment }) {
    const adjustments =
      adjustmentType === ADJUSTMENT_TYPE.RATE
        ? state.tempNextRateAdjustments
        : state.tempNextPriceAdjustments;
    const adjustmentIndex = adjustments.findIndex(
      adj => adj.id === adjustment.id,
    );
    adjustments.splice(adjustmentIndex, 1);
  },
  [SET_ADJUSTMENTS_TOTALS](state, { id, total }) {
    Vue.set(state.adjustmentsTotals, id, total);
  },
  [CLEAR_ADJUSTMENT_EDITS](state) {
    state.adjustmentsTotals = {};
    state.editedNextPriceAdjustments = [];
    state.editedNextRateAdjustments = [];
    state.tempNextPriceAdjustments = [];
    state.tempNextRateAdjustments = [];
  },
};

const actions = {
  setLockRequests({ commit }, lockRequests) {
    commit(SET_LOCK_REQUESTS, lockRequests);
  },
  async approveRequest({ dispatch, state, getters, rootGetters }, request) {
    const {
      orgId,
      requestId,
      investorLoanNumber,
      notes,
      acknowledgedPriorEdits = false,
      isCrossWorkflow = false,
      skipAdjustmentsAndParameters = false,
    } = request;

    const formattedParams = {};
    if (!skipAdjustmentsAndParameters) {
      const lockDeskParameters = isCrossWorkflow
        ? getters.lockDeskParametersCWF
        : getters.lockDeskParameters;
      if (lockDeskParameters) {
        lockDeskParameters.forEach(param => {
          formattedParams[param.id] = param.value;
        });
      }
    }

    const lockDeskEdits = rootGetters['lockDeskEditing/lockDeskEdits'];

    const payload = {
      orgId,
      investorLoanNumber,
      lockRequestId: requestId,
      reviewalAction: LOCK_REVIEWAL_ACTION.APPROVE,
      lockDeskParameters: formattedParams,
      notes: notes,
      adjustments: skipAdjustmentsAndParameters
        ? []
        : getters.getPendingAdjustments,
      lockDeskEdits: {
        buySide: {
          ...state.pendingLockDeskModifications['buySide'],
          ...lockDeskEdits['buySide'],
        },
        sellSide: {
          ...state.pendingLockDeskModifications['sellSide'],
          ...lockDeskEdits['sellSide'],
        },
      },
      acknowledgedPriorEdits,
    };
    return dispatch('reviewRequest', payload);
  },
  async denyRequest({ dispatch }, request) {
    const { notes, orgId, requestId } = request;

    const payload = {
      orgId,
      lockRequestId: requestId,
      reviewalAction: LOCK_REVIEWAL_ACTION.DENY,
      notes: notes,
    };
    return dispatch('reviewRequest', payload);
  },
  async crossflowRequest(
    { dispatch, state, getters, rootState, rootGetters },
    request,
  ) {
    const { initialRequestInfo, exceptionRequestInfo } = request;

    const initialRequestFormattedParams = {};
    const exceptionRequestFormattedParams = {};

    if (
      initialRequestInfo &&
      !initialRequestInfo.skipAdjustmentsAndParameters &&
      getters.lockDeskParameters
    ) {
      getters.lockDeskParameters.forEach(param => {
        initialRequestFormattedParams[param.id] = param.value;
      });
    }

    if (
      exceptionRequestInfo &&
      !exceptionRequestInfo.skipAdjustmentsAndParameters &&
      getters.lockDeskParametersCWF
    ) {
      getters.lockDeskParametersCWF.forEach(param => {
        exceptionRequestFormattedParams[param.id] = param.value;
      });
    }

    const initialLockDeskEdits = {
      buySide: {
        ...state.pendingLockDeskModifications.buySide,
        ...rootGetters['lockDeskEditing/lockDeskEdits'].buySide,
      },
      sellSide: {
        ...state.pendingLockDeskModifications.sellSide,
        ...rootGetters['lockDeskEditing/lockDeskEdits'].sellSide,
      },
    };

    for (const field of [
      'exceptionAmount',
      'exceptionComments',
      'exceptionReason',
      'exceptionType',
    ]) {
      // remove exception specific fields from initial lock desk edits
      if (field in initialLockDeskEdits.buySide) {
        delete initialLockDeskEdits.buySide[field];
      }
    }

    const initialRequestPayload = getLockDeskPayload(
      initialRequestInfo,
      initialRequestFormattedParams,
      getters.getPendingAdjustments,
      initialLockDeskEdits,
    );

    const buySide = {
      ...state.pendingLockDeskModifications.buySide,
      ...rootGetters['lockDeskEditing/lockDeskEdits'].buySide,
    };

    const withException =
      rootGetters['lockDeskEditing/editingResponseBySide']('buy').withException;

    if (withException?.netPrice.isChanged) {
      buySide['netPrice'] = withException.netPrice.value;
    }

    if (withException?.creditCost.isChanged) {
      buySide['creditCost'] = withException.creditCost.value;
    }
    const exceptionLockDeskEdits = {
      buySide: buySide,
      sellSide: {
        ...state.pendingLockDeskModifications.sellSide,
        ...rootGetters['lockDeskEditing/lockDeskEdits'].sellSide,
      },
    };

    const exceptionRequestPayload = getLockDeskPayload(
      exceptionRequestInfo,
      exceptionRequestFormattedParams,
      getters.getExceptionPendingAdjustments,
      exceptionLockDeskEdits,
    );

    const lockRequests = [initialRequestPayload, exceptionRequestPayload];
    const lockRequestInfo = exceptionRequestInfo || initialRequestInfo;
    const payload = {
      orgId: lockRequestInfo.orgId,
      investorLoanNumber: lockRequestInfo.investorLoanNumber,
      lockRequests: lockRequests.filter(request => request),
    };

    return dispatch('reviewCrossflowRequest', payload);
  },
  /**
   * Submit a reviewal (APPROVE or DENY) for a lock request
   *
   * @returns Boolean - whether the lock desk reviewal modal should be displayed for the request. This is
   *    currently used to force users to acknowledge prior lock desk edits. This happens after submitting the reviewal
   *    and not before, because it would be expensive on the database to do this check for prior lock desk edits for all
   *    pending lock requests.
   */
  async reviewRequest({ commit }, payload) {
    const { lockRequestId } = payload;
    commit(SET_REQUEST_LOADING_STATE, {
      requestId: lockRequestId,
      isLoading: true,
    });
    try {
      const response = await approveOrDenyLockRequest(payload);
      if (response) {
        commit(REMOVE_REQUEST_BY_ID, lockRequestId);
      }
      return { success: true, showRequestDetails: false };
    } catch (error) {
      commit(SET_REQUEST_LOADING_STATE, {
        requestId: lockRequestId,
        isLoading: false,
      });
      if (isUserException(error)) {
        const lockRequestAcknowledgeMessage = error.messages.find(
          msg => msg?.code === 'lock:needs_ack_edits',
        );
        // found a message from the backend saying that we need to acknowledge prior edits.
        if (lockRequestAcknowledgeMessage)
          return {
            success: false,
            showRequestDetails: true,
            message: lockRequestAcknowledgeMessage.message,
          };
      }
      onErrorHandler(error, 'pe-lock-desk-review-request');
      return { success: false, showRequestDetails: false };
    }
  },
  /**
   * Submit a reviewal (APPROVE or DENY) for a crossflow lock requests
   */
  async reviewCrossflowRequest({ commit }, payload) {
    const lockRequests = payload.lockRequests;

    lockRequests.forEach(request =>
      commit(SET_REQUEST_LOADING_STATE, {
        requestId: request.lockRequestId,
        isLoading: true,
      }),
    );

    try {
      const response = await crossflowLockRequest(payload);
      if (response) {
        lockRequests.forEach(request =>
          commit(REMOVE_REQUEST_BY_ID, request.lockRequestId),
        );
      }
      return { success: true, showRequestDetails: false };
    } catch (error) {
      lockRequests.forEach(request =>
        commit(SET_REQUEST_LOADING_STATE, {
          requestId: request.lockRequestId,
          isLoading: false,
        }),
      );
      if (isUserException(error)) {
        const lockRequestAcknowledgeMessage = error.messages.find(
          msg => msg?.code === 'lock:needs_ack_edits',
        );
        // found a message from the backend saying that we need to acknowledge prior edits.
        if (lockRequestAcknowledgeMessage)
          return {
            success: false,
            showRequestDetails: true,
            message: lockRequestAcknowledgeMessage.message,
          };
      }
      onErrorHandler(error, 'pe-lock-desk-cross-flow-review-request');
      return { success: false, showRequestDetails: false };
    }
  },
  setCountsByType({ commit }, countsByType) {
    commit(SET_COUNTS_BY_TYPE, countsByType);
  },
  setRequestDetails({ commit }, requestDetails) {
    commit(SET_REQUEST_DETAILS, requestDetails);
    commit(SET_INITIAL_TO_EXCEPTION_ADJUSTMENT_IDS);
  },
  setIsRequestDetailsLoading({ commit }, isLoading) {
    commit(SET_IS_REQUEST_DETAILS_LOADING, isLoading);
  },
  clearRequestDetails({ commit }) {
    commit(SET_REQUEST_DETAILS, {});
  },
  setInvestorLoanNumber({ commit }, investorLoanNumber) {
    commit(SET_INVESTOR_LOAN_NUMBER, investorLoanNumber);
  },
  setShowValidation({ commit }, showValidation) {
    commit(SET_SHOW_VALIDATION, showValidation);
  },
  modifyBuySideFields({ commit }, payload) {
    commit(SET_PENDING_LOCK_DESK_MODIFICATIONS, {
      lockSide: LOCK_DESK_MODIFICATIONS_BUY_SIDE,
      payload,
    });
  },
  modifySellSideFields({ commit, getters }, payload) {
    commit(SET_PENDING_LOCK_DESK_MODIFICATIONS, {
      lockSide: LOCK_DESK_MODIFICATIONS_SELL_SIDE,
      payload,
    });
    if (getters.pendingSellSideLockDeskModifications.clearSellSide) {
      commit(SET_PENDING_LOCK_DESK_MODIFICATIONS, {
        lockSide: LOCK_DESK_MODIFICATIONS_SELL_SIDE,
        payload: { clearSellSide: false },
      });
    }
  },
  setIsEditingSellSide({ commit }, isEditing) {
    commit(SET_IS_EDITING_SELL_SIDE, isEditing);
  },
  setIsEditingBuySide({ commit }, isEditing) {
    commit(SET_IS_EDITING_BUY_SIDE, isEditing);
  },
  clearBuySideModifications({ commit }) {
    commit(CLEAR_PENDING_LOCK_DESK_MODIFICATIONS, {
      lockSide: LOCK_DESK_MODIFICATIONS_BUY_SIDE,
    });
  },
  clearSellSideModifications({ commit }) {
    commit(CLEAR_PENDING_LOCK_DESK_MODIFICATIONS, {
      lockSide: LOCK_DESK_MODIFICATIONS_SELL_SIDE,
    });
  },
  clearBuySideModifiedField({ commit }, field) {
    commit(CLEAR_PENDING_LOCK_DESK_MODIFICATIONS, {
      lockSide: LOCK_DESK_MODIFICATIONS_BUY_SIDE,
      field,
    });
  },
  clearSellSideModifiedField({ commit }, field) {
    commit(CLEAR_PENDING_LOCK_DESK_MODIFICATIONS, {
      lockSide: LOCK_DESK_MODIFICATIONS_SELL_SIDE,
      field,
    });
  },
  async clearSellSideAdjustments({ commit, state }) {
    const priceAdjustments = state.tempNextPriceAdjustments;
    const rateAdjustments = state.tempNextRateAdjustments;
    priceAdjustments?.forEach(adj => {
      if (adj.is_sell_side) {
        adj.is_sell_side = false;
      }
    });
    rateAdjustments?.forEach(adj => {
      if (adj.is_sell_side) {
        adj.is_sell_side = false;
      }
    });
    commit(SET_EDITED_NEXT_PRICE_ADJUSTMENTS, priceAdjustments);
    commit(SET_EDITED_NEXT_RATE_ADJUSTMENTS, rateAdjustments);
    commit(SET_TEMP_NEXT_PRICE_ADJUSTMENTS, priceAdjustments);
    commit(SET_TEMP_NEXT_RATE_ADJUSTMENTS, rateAdjustments);
  },
  async clearSellSideValues({ commit, dispatch }) {
    await dispatch('clearSellSideAdjustments');
    await commit(SET_PENDING_LOCK_DESK_MODIFICATIONS, {
      lockSide: LOCK_DESK_MODIFICATIONS_SELL_SIDE,
      payload: {
        clearSellSide: true,
        exceptionAmount: '',
        exceptionComments: '',
        exceptionReason: '',
        exceptionType: '',
        extendBy: '',
        extensionFee: '',
        investor: '',
        investorLoanNumber: '',
        lockPeriod: '',
        noteRate: '',
        productName: '',
        priceBeforeAdjustments: '',
        relockFee: '',
        relockPeriod: '',
        netPrice: '',
      },
    });
    // There was a weird case where netprice would get recalculated, just need to make sure it's cleared.
    await commit(SET_PENDING_LOCK_DESK_MODIFICATIONS, {
      lockSide: LOCK_DESK_MODIFICATIONS_SELL_SIDE,
      payload: {
        clearSellSide: true,
        netPrice: '',
      },
    });
  },
};

function getLockDeskPayload(
  requestInfo,
  lockDeskParams,
  pendingAdj,
  lockDeskEdits,
) {
  if (!requestInfo) return null;

  let payload = {
    lockRequestId: requestInfo.requestId,
    reviewalAction: requestInfo.reviewalAction,
    notes: requestInfo.notes,
  };

  if (requestInfo.reviewalAction !== LOCK_REVIEWAL_ACTION.DENY) {
    payload = {
      ...payload,
      lockDeskParameters: lockDeskParams,
      adjustments: requestInfo.skipAdjustmentsAndParameters ? [] : pendingAdj,
      lockDeskEdits: lockDeskEdits,
      acknowledgedPriorEdits: requestInfo.acknowledgedPriorEdits,
    };
  }
  return payload;
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
